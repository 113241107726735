$(function () {
	if ("ontouchstart" in document.documentElement) {
		$('.jq_dropdown').click(function() {
			$(this).toggleClass('on');
		});
	}
	$('.m-nav-main__item').each(function (index) {
		if (index === 2) { // t�et� prvek (indexov�n� od 0)
			$(this).find('.m-nav-main__link.no-click').on('click', function (e) {
				e.preventDefault();
				$(this).closest('.m-nav-main__item').find('.jq_dropdown').toggleClass('on');
			});
		}
	});
});

